import { GetGlobalMetadataQuery } from 'server/repository/hygraph/generated/graphqlTypes';

export const getAdditionalLinksForNextSEO = (
  globalMetadata: GetGlobalMetadataQuery['globalMetaData'],
): {
  rel: string;
  href: string;
}[] => {
  const links = [];
  if (globalMetadata) {
    if (globalMetadata.favicon?.url) {
      links.push({
        rel: 'icon',
        href: globalMetadata.favicon?.url ?? '',
      });
    }
    if (globalMetadata.appleTouchIcon?.url) {
      links.push({
        rel: 'apple-touch-icon',
        href: globalMetadata.appleTouchIcon?.url ?? '',
      });
    }
    if (globalMetadata.twitterSeo) {
      links.push({
        rel: 'apple-touch-icon',
        href: globalMetadata.appleTouchIcon?.url ?? '',
      });
    }

    if (!globalMetadata.favicon?.url && globalMetadata.logo?.url) {
      links.push({
        rel: 'icon',
        href: globalMetadata.logo?.url ?? '',
      });
    }
  }
  return links;
};
