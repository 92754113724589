import React from 'react';

import { conditionalClasses } from '@/utils';

export interface TextProps {
  children: React.ReactNode;
  as?: 'p' | 'span' | 'sup';
  variant?: 'body' | 'lead';
  type?: 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs';
  className?: string;
  'data-testid'?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

const _NOT_PURGE_CLASSES = [
  'text-body-m',
  'leading-body-m',
  'tracking-body-m',
  'text-lead-m',
  'leading-lead-m',
  'tracking-lead-m',
  'text-body-l',
  'leading-body-l',
  'tracking-body-l',
  'text-lead-l',
  'leading-lead-l',
  'tracking-lead-l',
  'text-body-xl',
  'leading-body-xl',
  'tracking-body-xl',
  'text-lead-xl',
  'leading-lead-xl',
  'tracking-lead-xl',
  'text-body-xxl',
  'leading-body-xxl',
  'tracking-body-xxl',
  'text-lead-xxl',
  'leading-lead-xxl',
  'tracking-lead-xxl',
  'text-body-s',
  'leading-body-s',
  'tracking-body-s',
  'text-lead-xs',
  'text-body-xs',
  'leading-body-xs',
  'tracking-body-xs',
  'text-lead-xs',
];

export const ParagraphText: React.FC<TextProps> = ({
  children,
  variant = 'body',
  as: Component = 'p',
  type = 'm',
  className,
  'data-testid': dataTestId,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  return (
    <Component
      data-testid={dataTestId}
      className={conditionalClasses(
        [`text-${variant}-${type} leading-${variant}-${type} tracking-${variant}-${type}`],
        className,
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};
