import { conditionalClasses } from '@/utils';
import { ParagraphText } from '../ParagraphText';
import { Border, Button, Container } from './styles';

const ArePropsEmpty = <T extends Record<string, any>>(props: T): props is T => {
  return Object.keys(props).length === 0;
};

type LiquidButtonProps =
  | {
      variant?: 'none';
      width: number;
      height: number;
      color: string;
      text: string;
      textColor: string;
      useCustomColorsAsImportant?: boolean;
      isWidthAuto?: boolean;
      onButtonClick?: () => void;
      className?: string;
      disabled?: boolean;
      dataTest?: string;
      type?: 'submit' | 'button';
      as?: 'button' | 'a' | 'div';
    }
  | {
      variant?: 'primary' | 'secondary';
      onButtonClick?: () => void;
      text: string;
      className?: string;
      disabled?: boolean;
      dataTest?: string;
      type?: 'submit' | 'button';
      as?: 'button' | 'a' | 'div';
    };

export const LiquidButton = ({
  text,
  className,
  onButtonClick = () => {},
  disabled = false,
  variant = 'none',
  type = 'button',
  dataTest,
  as: Component = 'div',
  ...props
}: LiquidButtonProps) => {
  if (variant !== 'none') {
    return (
      <Component
        type={type}
        onClick={onButtonClick}
        disabled={disabled}
        data-test={dataTest}
        className={conditionalClasses(
          'font-secondary min-w-[150px] rounded-[100px] p-[5px] text-center',
          {
            'text-white bg-primary': variant === 'primary',
            'text-black bg-white': variant === 'secondary',
          },
          className,
        )}
      >
        <div className="border-1 border-white inline-block w-full rounded-[100px]">
          <ParagraphText className="p-3">{text}</ParagraphText>
        </div>
      </Component>
    );
  }

  if (ArePropsEmpty(props)) {
    return null;
  }

  const { width, height, color, textColor, useCustomColorsAsImportant, isWidthAuto } = props || {
    width: 0,
    height: 0,
    color: 'transparent',
    textColor: 'transparent',
    useCustomColorsAsImportant: false,
    isWidthAuto: false,
  };

  return (
    <Component onClick={onButtonClick} disabled={disabled} data-test={dataTest} type={type}>
      <Container width={width} height={height} isWidthAuto={isWidthAuto}>
        <Border
          width={width}
          height={height}
          color={textColor}
          useCustomColorsAsImportant={useCustomColorsAsImportant}
          isWidthAuto={isWidthAuto}
        />
        <Button
          textColor={textColor}
          bgColor={color}
          width={width}
          height={height}
          useCustomColorsAsImportant={useCustomColorsAsImportant}
          isWidthAuto={isWidthAuto}
        >
          {text}
        </Button>
      </Container>
    </Component>
  );
};
