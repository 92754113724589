import { ReactNode, FC, SyntheticEvent } from 'react';
import Link from 'next/link';

interface NextLinkProps {
  children: ReactNode;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  className?: string;
  key?: string;
  onClick?: (event: SyntheticEvent) => void;
  onKeyUp?: (event: SyntheticEvent) => void;
  onKeyDown?: (event: SyntheticEvent) => void;
  onMouseEnter?: (event: SyntheticEvent) => void;
  onMouseLeave?: (event: SyntheticEvent) => void;
  tabIndex?: number;
  rel?: string;
}

export const NextLink: FC<NextLinkProps> = ({ href, children, target = '_self', ...rest }) => {
  if (target === '_blank') {
    return (
      <a href={href ?? ''} {...rest} target={target}>
        {children}
      </a>
    );
  }

  return (
    <Link href={href ?? ''} {...(rest as Partial<NextLinkProps>)} prefetch={false}>
      {children}
    </Link>
  );
};
