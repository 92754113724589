const WIDTH_DEFAULT = 1272;
const HEIGHT_DEFAULT = 764;
export function getImageDimensions(url: string) {
  const match = url.match(/\/(\d+x\d+)\//);

  if (!match) return { width: WIDTH_DEFAULT, height: HEIGHT_DEFAULT };

  const [widthStr, heightStr] = match[1].split('x');

  // Parse width and height as integers
  const width = parseInt(widthStr, 10) || WIDTH_DEFAULT;
  const height = parseInt(heightStr, 10) || HEIGHT_DEFAULT;
  return { width, height };
}
