import styled from 'styled-components';
import { COLORS } from 'styles/constants';

export const Container = styled.div<{ width: number; height: number; isWidthAuto?: boolean }>`
  display: inline-block;
  position: relative;
  width: ${(p) => (p.isWidthAuto ? 'auto' : `${p.width}px`)};
  height: ${(p) => `${p.height + 60}px`};
  min-width: 150px;
  max-width: 100%;
`;

export const Border = styled.div<{
  width: number;
  height: number;
  color: string;
  useCustomColorsAsImportant?: boolean;
  isWidthAuto?: boolean;
}>`
  position: absolute;
  ${(p) =>
    p.useCustomColorsAsImportant
      ? `
      border: 1px solid ${p.color || COLORS.white};
    `
      : `border: 1px solid var(--color-text, ${COLORS.white});`}
  border-radius: 100px;
  width: calc(100% - 10px);
  height: ${(p) => `${p.height - 10}px`};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;

  @media (min-width: 768px) {
    width: ${(p) => (p.isWidthAuto ? 'calc(100% - 10px)' : `${p.width - 10}px`)};
  }
`;

export const Button = styled.div<{
  width: number;
  height: number;
  textColor: string;
  bgColor: string;
  useCustomColorsAsImportant?: boolean;
  isWidthAuto?: boolean;
}>`
  position: relative;
  text-align: center;
  width: ${(p) => (p.isWidthAuto ? 'auto' : '100%')};
  height: ${(p) => `${p.height}px`};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: ${(p) => `${p.height}px`};
  letter-spacing: 0.1em;
  ${(p) =>
    p.useCustomColorsAsImportant
      ? `
      color: ${p.textColor};
      background: ${p.bgColor || COLORS.primary};
    `
      : `
    color: var(--color-text, ${p.textColor});
    background: var(--color-neutral, ${p.bgColor});
  `}
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.8px;
  padding: ${(p) => (p.isWidthAuto ? '0 20px' : '')};
  @media (min-width: 768px) {
    width: ${(p) => (p.isWidthAuto ? 'auto' : `${p.width}px`)};
    font-size: 14px;
    letter-spacing: 2.1px;
  }
`;
