import { ComponentProps, FC, ReactNode } from 'react';
import Image from 'next/image';
import FallBackImageForNoImage from './assets/fallback_image.webp';
import { FALLBACK_IMAGE, MOBILE_MEDIA_BP } from '../../config/config';
import { ImagesService } from 'service/images/imagesService';

// Image that shows up before we load FallBackImageForNoImage
const blurredFallbackImage = FALLBACK_IMAGE;

const getSizesForImage = (sizes?: string) => {
  return !sizes ? '(max-width: 768px) 33vw, (max-width: 1200px) 50vw, 100vw' : sizes;
};

const _BPs = [
  '[@media(max-width:767px)]:block',
  '[@media(min-width:768px)]:block',
  '[@media(min-width:1200px)]:block',
  '[@media(min-width:1400px)]:block',
  '[@media(min-width:1680px)]:block',
  '[@media(min-width:1920px)]:block',
] as const;
type ImageProps = { src: string; sources?: undefined };
type PictureProps = {
  sources?: {
    srcSet?: string;
    media?: string;
    type?: string;
    width?: number;
    height?: number;
  }[];
  children?: ReactNode;
  src?: undefined;
};
type CommonPictureImageProps = {
  className?: string;
  preferredWidth?: number;
  mimeType?: string;
  dataTestId?: string;
} & Omit<ComponentProps<typeof Image>, 'src'>;

export type Props = (ImageProps | PictureProps) & CommonPictureImageProps;

const NextImage: FC<Props> = ({ alt, className, children, ...props }) => {
  const { preferredWidth, mimeType, ...rest } = props;
  const imageShouldBeFill = !props.width && !props.height;

  if (props.src) {
    const serveImageUnoptimized =
      ImagesService.serveImageUnoptimized(props.src as string, props.quality as number) ||
      props.unoptimized;

    return (
      <>
        <Image
          {...rest}
          alt={`Picture - ${alt}`}
          fill={imageShouldBeFill}
          width={!imageShouldBeFill ? props.preferredWidth || props.width : undefined}
          height={!imageShouldBeFill ? props.height || 600 : undefined}
          className={className}
          sizes={props.sizes || getSizesForImage(props.sizes)}
          quality={props.quality || 75}
          unoptimized={serveImageUnoptimized}
          loader={() => {
            if (serveImageUnoptimized) {
              return props.src!;
            }
            return ImagesService.getImageUrlWithCompressionParams(
              props.src ?? '',
              props.quality as number,
              preferredWidth || (props.width as number) || 1440,
              mimeType,
            );
          }}
          src={ImagesService.transformImageSrc(props.src!)}
        />
      </>
    );
  }

  if (props.sources?.length) {
    const uniqueObjArraySources = [
      ...new Map(props.sources.map((item) => [item.media, item])).values(),
    ];

    const filteredUniqueObjArraySources = uniqueObjArraySources.filter((image) => image.srcSet);

    return (
      <div className="relative w-full h-full">
        {filteredUniqueObjArraySources?.map((source: any, index: number) => {
          const isMobile = source.media === MOBILE_MEDIA_BP;
          const isPriorityHighNeeded =
            (filteredUniqueObjArraySources.length === 1 && props.priority) ||
            (index === 0 && props.priority);
          const serveImageUnoptimized =
            ImagesService.serveImageUnoptimized(
              source?.srcSet as string,
              props.quality as number,
            ) || props.unoptimized;

          return (
            <Image
              priority={isPriorityHighNeeded}
              key={`sources-${index}`}
              src={ImagesService.transformImageSrc(source.srcSet)}
              alt={`Picture - ${source.media}`}
              width={source.width ?? props?.width}
              height={source.height ?? props?.height}
              fill={!(source.width ?? props?.width) || !(source.height ?? props?.height)}
              sizes={(!isMobile && getSizesForImage(source.sizes)) || `${MOBILE_MEDIA_BP} 300vw`}
              quality={props.quality || 75}
              unoptimized={serveImageUnoptimized}
              loader={() => {
                if (serveImageUnoptimized) {
                  return source.srcSet!;
                }
                return ImagesService.getImageUrlWithCompressionParams(
                  source.srcSet || '',
                  props.quality as number,
                  props.preferredWidth ?? (props.width as number),
                  mimeType,
                  isMobile,
                );
              }}
              className={`${className ?? ''} ${
                filteredUniqueObjArraySources.length > 1 ? 'hidden' : ''
              } [@media${source.media.replace(' ', '')}]:block `}
              placeholder={props.placeholder}
              blurDataURL={props.blurDataURL}
            >
              {children}
            </Image>
          );
        })}
      </div>
    );
  }

  return (
    <Image
      src={FallBackImageForNoImage}
      alt="Logo Image"
      placeholder="blur"
      blurDataURL={blurredFallbackImage}
      fill
      className={className}
      sizes={getSizesForImage()}
    />
  );
};

export const customImageLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

export default NextImage;
