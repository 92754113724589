import React, { RefObject } from 'react';
import clsx from 'clsx';

export interface ITypography {
  tag: string;
  type: string;
  children?: React.ReactNode;
  id?: string;
  className?: string;
  itemProp?: string;
  ref?: RefObject<HTMLDivElement>;
  onMouseOver?: () => void;
  onKeyDown?: () => void;
  onMouseLeave?: () => void;
  isContainer?: boolean;
}

const TitleStyles: any = {
  xl: 'title-style-xl',
  lg: 'title-style-lg',
  md: 'title-style-md',
  sm: 'title-style-sm',
  xs: 'title-style-xs',
  xxs: 'title-style-xxs',
  xxxs: 'title-style-xxxs',
  august: 'title-style-august',
};

export const Title = (props: ITypography) => {
  const { tag, type, className, isContainer, children, ...rest } = props;
  const Component = tag as any;

  if (!children && !isContainer) {
    return null;
  }

  return (
    <Component className={clsx(TitleStyles[type], className)} {...rest}>
      {children}
    </Component>
  );
};

const TextStyles: any = {
  xxl: 'text-style-xxl',
  xl: 'text-style-xl',
  lg: 'text-style-lg',
  md: 'text-style-md',
  sm: 'text-style-sm',
  xs: 'text-style-xs',
  lead: 'text-style-lead',
  leadxs: 'text-style-leadxs',
  link: 'text-style-link',
  list: 'text-style-list',
  listsm: 'text-style-listsm',
};

export const Text = (props: ITypography) => {
  const { tag, type, className, isContainer, children, ...rest } = props;
  const Component = tag as any;

  if (!children && !isContainer) {
    return null;
  }

  return (
    <Component className={clsx('m-0 p-0 block', TextStyles[type], className)} {...rest}>
      {children}
    </Component>
  );
};
