import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twm = extendTailwindMerge({
  extend: {
    classGroups: {
      leading: [
        'leading-headline-xl',
        'leading-headline-l',
        'leading-headline-m',
        'leading-headline-s',
        'leading-headline-xs',
        'leading-headline-xxs',
        'leading-headline-xxxs',
        'leading-body-xxl',
        'leading-body-xl',
        'leading-body-l',
        'leading-body-m',
        'leading-body-s',
        'leading-lead-m',
        'leading-lead-s',
      ],
    },
  },
});

export function conditionalClasses(...inputs: ClassValue[]) {
  return twm(clsx(inputs));
}
