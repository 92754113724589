export const COLORS = {
  primary: '#A45A2A',
  secondary: '#EFDBB2',
  error: '#E22C43',
  white: '#ffffff',
  black: '#000000',
  darkGray: '#5a5e5a',
};

export const AVAILABLE_THEMES = {
  christmas_blue_edition: 'christmas_blue_edition',
  christmas: 'christmas',
  suprema: 'suprema',
};

export type AVAILABLE_THEMES_TYPE = keyof typeof AVAILABLE_THEMES;

/**
 * TODO: create variables if needed and use in code
 * #292b29
 * #5a5e5a
 * #f4f5f4
 * #d4ded3
 * #BBC4BA
 * #ccc
 * #DF002C
 * #FA85BD
 */
